import React, { useState } from "react";
import styled from "styled-components";
// Components
import FullButton from "src/components/Elements/FullButton";
// Assets
// import HeaderImage from "../../../assets/images/cricle.png";
import HeaderImage from "../../../assets/images/globelimg.svg";
// import QuotesIcon from "../../assets/svg/Quotes";
// import Dots from "../../assets/svg/Dots";
import "src/assets/images/headerBg.png";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Close, Instagram, LinkedIn, YouTube } from "@mui/icons-material";
import "./styles.css";
import { Input } from "reactstrap";
import { PostWitoutToken } from "src/api/apiConfig";
import { BookDemoApi ,MailSendingApi} from "src/api/ApiConnect";
import BookNow from "./BookNow";
export default function Header() {
  const [openJoinFor, setOpenJoinFor] = useState(false);
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState();

  //Validation declaration
  const [localfullnameError, setlocalfullnameError] = useState(null);
  const [localmobileError, setlocalmobileError] = useState(null);
  const [localemailError, setlocalemailError] = useState(null);
  const numRegEx = "^/D/";
  const handleNumber = (val) => {
    if (val) {
      if (/^[0-9]+$/.test(val) && val.length < 12) setNumber(val);
    } else setNumber("");
  };
  const onSubmit = async (e) => {
    //Validation Check
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const pattern = new RegExp(/^[0-9\b]+$/);
    setlocalfullnameError(null);
    setlocalmobileError(null);
    setlocalemailError(null);

    if (name.length === 0) {
      setlocalfullnameError("Please enter your full name");
      return;
    }

    if (number.length === 0) {
      setlocalmobileError("Please enter your mobile number");
      return;
    }

    if (email.length === 0) {
      setlocalemailError("Please enter your email");
      return;
    }

    if (!re.test(email)) {
      setlocalemailError("Enter valid Email Id");
      return;
    }

    setLoading(true);

    //Api call
    const RequestBody = {
      USER_FULLNAME: name,
      USER_MOBILE: number,
      USER_EMAIL: email,
      COURSE_CATEGORY: "ERP",
      SOURCE:'TAGSKILLS'
    };

    const data = await PostWitoutToken(BookDemoApi, RequestBody);
    mailsending();
    setLoading(false);
    if (data?.IsSuccess) {
      setOpenJoinFor(false);
      alert(data.Message);
    }
  };

  const mailsending= async (e) => {

    const now = new Date();
    const formattedDate = now.toLocaleString();

    const RequestBody = { 
      NAME: name,
      TIME: formattedDate
     }
    const data = await PostWitoutToken(MailSendingApi,RequestBody)
  }

  const handleDialogClose = () => {
    setOpenJoinFor(false);
  };
  return (
    <div id="header">
      <Wrapper id="home" className="purpleBg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className="grid1">
            <ContentWrapper>
              <Typography variant="h3" className="font30 whiteColor">
                We are Training Academy
              </Typography>
              <br />
              <Typography variant="h6" className="font24 whiteColor">
                Join TagSkills, where education meets experience, and success
                becomes a reality. Your learning journey starts here!
              </Typography>
              <br />
              <Button
                style={{
                  background: "#9966CC",
                  color: "white",
                  borderRadius: "30px",
                  width: "200px",
                  textTransform: "none",
                }}
                onClick={() => setOpenJoinFor(true)}
              >
                Join for free
              </Button>
            </ContentWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="grid2"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {/* <ImageWrapper>
              <div
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  // maxHeight: "90%",
                  // maxWidth: "9/0%",
                }}
              >
                <Img loading="lazy" src={HeaderImage} />
              </div>
              <InstaLabel
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/tagskills2020?igsh=YzZmNXpjNXZneHlu",
                    "_blank"
                  )
                }
              >
                1k
              </InstaLabel>
              <LinkedInLabel
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/tag-skills",
                    "_blank"
                  )
                }
              >
                17k
              </LinkedInLabel>
              <YoutubeLabel
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCnrhyp2JvcNQb3kFeRziDLA",
                    "_blank"
                  )
                }
              >
                7.39k
              </YoutubeLabel>
              <QuoteWrapper className="flexCenter darkBg radius8">
                <div>
                  <p className="font15 whiteColor">
                    <em>Trusted by 50K Daily Active Users 1,000+</em>
                  </p>
                </div>
              </QuoteWrapper>
            </ImageWrapper> */}

            <ImageWrapper>
              <Img src={HeaderImage} />
              <InstaIcon
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/tagskills2020?igsh=YzZmNXpjNXZneHlu",
                    "_blank"
                  )
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Instagram style={{ fill: "#FA6488", fontSize: "40px" }} />
                </div>
                <InstaLabel>1k</InstaLabel>
              </InstaIcon>
              {/* <InstaLabel>3k</InstaLabel> */}
              <LinkedInIcon
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/tag-skills",
                    "_blank"
                  )
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <LinkedIn style={{ fill: "#68A3DA", fontSize: "40px" }} />
                </div>
                <LinkedInLabel>22k+</LinkedInLabel>
              </LinkedInIcon>

              <YoutubeIcon
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCnrhyp2JvcNQb3kFeRziDLA",
                    "_blank"
                  )
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <YouTube style={{ fill: "#FF2525", fontSize: "40px" }} />
                </div>
                <YoutubeLabel>9.56k+</YoutubeLabel>
              </YoutubeIcon>
              <QuoteWrapper className="flexCenter darkBg radius8">
                <div>
                  <p className="font15 whiteColor">
                    <em>Trusted by 25K Daily Active Users 5,000+</em>
                  </p>
                </div>
              </QuoteWrapper>
            </ImageWrapper>
          </Grid>
        </Grid>
      </Wrapper>
      <Curve className="flexSpaceCenter purpleBg" />
      <InnerWrapper>
        <Typography variant="h4">
          <b>Your Gateway to success, We provide Experience!</b>
        </Typography>
        <br />
        <Typography variant="body2" className="font12">
          Transform your career with TagSkills, the leading online platform for
          ERP courses that sets the benchmark for excellence. Specializing in
          S/4 HANA, from MM to FICO, and including Salesforce, our
          comprehensive, interactive training propels you ahead in the
          digital-first economy. Enroll with TagSkills today and start your
          journey towards becoming an ERP expert, fully equipped to meet the
          demands of tomorrow's technology landscape.
        </Typography>
      </InnerWrapper>
      {openJoinFor && (
        <BookNow onClose={handleDialogClose} openJoinFor={openJoinFor} />
      )}
    </div>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  margin: 0;
  height: 100%;
  min-height: 140px;
  max-height: 60vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 560px) {
    max-height: 100vh;
  }
`;
const Curve = styled.section`
  height: 150px;
  margin-top: -40px;
  margin: 0;
  border-bottom-left-radius: 80%;
  border-bottom-right-radius: 80%;
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  background: red;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 60px;
  height: 100%;
`;
const ImageWrapper = styled.div`
  position: relative;
  width: 500px;
  height: 400px;
  display: flex;
  justify-content: center;
`;
const Img = styled.img`
  width: 100%;
  max-width: 465px;
  height: 100%;
  max-height: 400px;
  @media (max-width: 560px) {
    width: 100%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: -90px;
  top: 65%;
  max-width: 280px;
  padding: 10px;
  z-index: 99;
  border-radius: 20px;
  background: linear-gradient(160deg, #82e0aa 6.59%, #6567f3 51.38%);
  box-shadow: 0px 19px 37px 0px rgba(86, 86, 86, 0.25);
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 390px) {
    top: 65%;
  }
  @media (max-width: 370px) {
    top: 65%;
  }
  // @media (max-width: 360px) {
  //   top: 54%;
  // }
  // @media (max-width: 340px) {
  //   top: 51%;
  // }
  // @media (max-width: 330px) {
  //   top: 50%;
  // }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;

  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const InstaIcon = styled.div`
  position: absolute;
  cursor: pointer;
  // border: 1px solid;
  height: 50px;
  width: 50px;
  border-radius: 8px;
  top: 15%;
  left: 18%;
  background: white;
  z-index: 9;
  @media (max-width: 560px) {
    // display: none;
    // height: 40px;
    // width: 40px;
    // top: 15%;
    // left: 10%;
  }
  @media (max-width: 350px) {
    top: 18%;
  }
`;
const InstaLabel = styled.div`
  position: absolute;
  cursor: pointer;
  //   border: 1px solid;
  background: #fa6488;
  text-align: center;
  font-size: 11px;
  color: white;
  height: 15px;
  width: 40px;
  border-radius: 8px;
  bottom: -8px;
  left: 6px;
  // top: 26%;
  // left: 19%;
  z-index: 9;
  // @media (max-width: 560px) {
  //   // display: none;
  //   // height: 15px;
  //   // width: 30px;
  //   top: 26%;
  //   left: 19%;
  // }
  // @media (max-width: 350px) {
  //   top: 29%;
  //   left: 20%;
  // }
  // @media (max-width: 460px) {
  //   left: 20%;
  // }
  // @media (max-width: 420px) {
  //   top: 21%;
  // }
  // @media (max-width: 400px) {
  //   width: 35px;
  //   top: 20%;
  // }
  // @media (max-width: 370px) {
  //   width: 35px;
  //   top: 18.5%;
  // }
  // @media (max-width: 350px) {
  //   width: 33px;
  //   top: 17.5%;
  // }
  // @media (max-width: 330px) {
  //   width: 30px;
  //   top: 16.5%;
  // }
`;
const LinkedInIcon = styled.div`
  position: absolute;
  cursor: pointer;
  // border: 1px solid;
  height: 50px;
  width: 50px;
  border-radius: 8px;
  top: 3%;
  left: 68%;
  background: white;
  z-index: 9;
  @media (max-width: 560px) {
    // display: none;
    // height: 40px;
    // width: 40px;
    // top: 5%;
    left: 72%;
  }
  @media (max-width: 420px) {
    // display: none;
    // height: 40px;
    // width: 40px;
    top: 5%;
    // left: 72%;
  }
  @media (max-width: 400px) {
    top: 10%;
  }
  @media (max-width: 350px) {
    top: 12%;
  }
`;
const LinkedInLabel = styled.div`
  position: absolute;
  cursor: pointer;
  //   border: 1px solid;
  background: #68a3da;
  text-align: center;
  font-size: 11px;
  color: white;
  height: 15px;
  width: 40px;
  border-radius: 8px;
  bottom: -8px;
  left: 6px;
  // top: 14%;
  // left: 69%;
  z-index: 9;
  // @media (max-width: 560px) {
  //   // display: none;
  //   // height: 15px;
  //   // width: 30px;
  //   top: 14%;
  //   left: 73%;
  // }
  // @media (max-width: 420px) {
  //   top: 16%;
  //   left: 73.5%;
  // }
  // @media (max-width: 400px) {
  //   // display: none;

  //   top: 21%;
  //   // left: 69%;
  // }
  // @media (max-width: 350px) {
  //   top: 23%;
  // }
  // @media (max-width: 500px) {
  //   // display: none;
  //   // height: 15px;
  //   // width: 30px;
  //   top: 14%;
  //   left: 65%;
  // }
  // @media (max-width: 490px) {
  //   // display: none;
  //   // height: 15px;
  //   // width: 30px;
  //   top: 14%;
  //   left: 66%;
  // }
  // @media (max-width: 480px) {
  //   // display: none;
  //   // height: 15px;
  //   // width: 30px;
  //   top: 14%;
  //   left: 67%;
  // }
  // @media (max-width: 470px) {
  //   // display: none;
  //   // height: 15px;
  //   // width: 30px;
  //   top: 14%;
  //   left: 69%;
  // }
  // @media (max-width: 435px) {
  //   // display: none;
  //   height: 15px;
  //   width: 40px;
  //   top: 13.5%;
  //   left: 69%;
  // }
  // @media (max-width: 400px) {
  //   // display: none;
  //   height: 15px;
  //   width: 36px;
  //   top: 12.5%;
  //   left: 69%;
  // }
  // @media (max-width: 380px) {
  //   // display: none;
  //   height: 15px;
  //   width: 33px;
  //   top: 11.5%;
  //   left: 69%;
  // }
  // @media (max-width: 350px) {
  //   // display: none;
  //   height: 15px;
  //   width: 30px;
  //   top: 10.5%;
  //   left: 69%;
  // }
`;
const YoutubeIcon = styled.div`
  position: absolute;
  cursor: pointer;
  // border: 1px solid;
  height: 50px;
  width: 50px;
  border-radius: 8px;
  top: 87%;
  left: 60%;
  background: white;
  z-index: 9;
  @media (max-width: 560px) {
    // display: none;
    // height: 50px;
    // width: 50px;
    // top: 5%;
    left: 55%;
  }
  @media (max-width: 400px) {
    top: 82%;
  }
  @media (max-width: 350px) {
    top: 78%;
  }
`;
const YoutubeLabel = styled.div`
  position: absolute;
  cursor: pointer;
  //   border: 1px solid;
  background: #ff2525;
  text-align: center;
  font-size: 11px;
  color: white;
  height: 15px;
  width: 40px;
  border-radius: 8px;
  bottom: -8px;
  left: 6px;
  // top: 97.5%;
  // left: 61.5%;
  z-index: 9;
  // @media (max-width: 560px) {
  //   // display: none;
  //   // height: 15px;
  //   // width: 30px;
  //   top: 97.5%;
  //   left: 56.5%;
  // }
  // @media (max-width: 500px) {
  //   // display: none;
  //   // height: 15px;
  //   // width: 30px;
  //   top: 94%;
  //   left: 62%;
  // }
  // @media (max-width: 480px) {
  //   left: 65%;
  // }
  // @media (max-width: 445px) {
  //   top: 92%;
  // }
  // @media (max-width: 435px) {
  //   top: 91%;
  // }
  // @media (max-width: 425px) {
  //   top: 88%;
  // }
  // @media (max-width: 415px) {
  //   top: 85.5%;
  // }
  // @media (max-width: 400px) {
  //   width: 36px;
  //   top: 82.5%;
  // }
  // @media (max-width: 385px) {
  //   top: 80%;
  // }
  // @media (max-width: 375px) {
  //   width: 33px;
  //   top: 78%;
  // }
  // @media (max-width: 365px) {
  //   top: 75%;
  // }
  // @media (max-width: 355px) {
  //   top: 73%;
  // }
  // @media (max-width: 345px) {
  //   top: 71.5%;
  // }
  // @media (max-width: 335px) {
  //   width: 30px;
  //   top: 70%;
  // }
  // @media (max-width: 330px) {
  //   width: 30px;
  //   top: 68%;
  // }
`;
const InnerWrapper = styled.div`
  text-align: center;
  padding: 30px 150px;
  @media (max-width: 560px) {
    padding: 15px;
  }
`;
