import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import { Close } from "@mui/icons-material";
import LogoIcon from "src/assets/icons/Logo.png";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <img
            //   className={styles.navLogo}
            style={{
              width: "36px",
              height: "36px",
              alignSelf: "flex-start",
              marginRight: "16px",
            }}
            alt="logo.png"
            src={LogoIcon}
          />
          <h1 className="whiteColor font20" style={{ marginLeft: "15px" ,color:"white"}}>
            TagSkills
          </h1>
        </div>
        <CloseBtn
          onClick={() => toggleSidebar(!sidebarOpen)}
          className="animate pointer"
        >
          <Close />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        <li className="whiteColor font15 pointer">
          <Link
            activeClass="active"
            style={{ padding: "10px 15px" }}
            to="courses"
            spy={true}
            smooth={true}
            offset={-80}
          >
            Courses
          </Link>
        </li>
        <li className="whiteColor font15 pointer">
          <Link
            activeClass="active"
            style={{ padding: "10px 15px" }}
            to="success"
            spy={true}
            smooth={true}
            offset={-80}
          >
            Success Story
          </Link>
        </li>
        <li className="whiteColor font15 pointer">
          <Link
            activeClass="active"
            style={{ padding: "10px 15px" }}
            to="projects"
            spy={true}
            smooth={true}
            offset={-80}
          >
            About Us
          </Link>
        </li>
        <li className="whiteColor font15 pointer">
          <Link
            activeClass="active"
            style={{ padding: "10px 15px" }}
            to="blog"
            spy={true}
            smooth={true}
            offset={-80}
          >
            Contact
          </Link>
        </li>
        <li className="whiteColor font15 pointer">
          <Link
            activeClass="active"
            style={{ padding: "10px 15px" }}
            to="blog"
            spy={true}
            smooth={true}
            offset={-80}
          >
            Jobs
          </Link>
        </li>
        <li className="whiteColor font15 pointer">
          <Link
            activeClass="active"
            style={{ padding: "10px 15px" }}
            to="blog"
            spy={true}
            smooth={true}
            offset={-80}
          >
            Blogs
          </Link>
        </li>
        <li className="whiteColor font15 pointer flexCenter">
          <a
            href="/"
            className="radius6 lightBg"
            style={{ padding: "10px 15px" }}
          >
            Log in
          </a>
        </li>
      </UlStyle>
      {/* <UlStyle className="flexSpaceCenter">
        <li className="semiBold font15 pointer">
          <a
            href="/"
            style={{ padding: "10px 30px 10px 0" }}
            className="whiteColor"
          >
            Log in
          </a>
        </li>
        <li className="semiBold font15 pointer flexCenter">
          <a
            href="/"
            className="radius8 lightBg"
            style={{ padding: "10px 15px" }}
          >
            Get Started
          </a>
        </li>
      </UlStyle> */}
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
