import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../Blogs/BlogEditor.css";
import BlogCard from "../../Blogs/BlogCard";
import BlogDetails from "./BlogDetails";
import { DisplayblogsApi, SubCourseApi } from "src/api/ApiConnect";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import {
  TextField,
  IconButton,
  Chip,
  InputAdornment,
  Grid,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation } from "react-router-dom";
import { loadCombo } from "src/api/api";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";
import { PostWitoutToken } from "src/api/apiConfig";
import styled from "styled-components";
import { Link } from "react-scroll";
import LogoIcon from "src/assets/icons/Logo.png";
import { ArrowBack } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import { Popper } from "@mui/material";
import { useNavigate } from "react-router-dom";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function Blogs() {
  const navigate = useNavigate();
  const location = useLocation();

  const [onoffcompo, setOnoffcompo] = useState(false);

  const [searchtext, setSearchtext] = useState("");
  const [selectedblog, setSelectedblog] = useState([]);
  const [selectedcourse_var, setSelectedcourse] = useState("");
  const [dropdownData, setDropdownData] = useState([]);

  const [chips, setChips] = useState([]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && searchtext.trim() !== "") {
      if (!chips.includes(searchtext)) {
        setChips((prev) => [...prev, searchtext.trim()]);
      }
      setSearchtext("");
    }
  };

  useEffect(() => {
    loadDropdownData();
    loadblogs();
  }, [chips, selectedcourse_var]);

  const loadDropdownData = async () => {
    const RequestBody = {
      USER_COURSE_TYPE: "ERP",
    };
    const response = await PostWitoutToken(SubCourseApi, RequestBody);
    if (response?.IsSuccess) {
      setDropdownData(response);
    } else {
      setDropdownData([]);
    }
  };

  // Remove a chip when clicked
  const handleDelete = (chipToDelete) => {
    setChips((prev) => prev.filter((chip) => chip !== chipToDelete));
  };

  const addChip = () => {
    if (searchtext.trim() !== "" && !chips.includes(searchtext)) {
      setChips((prev) => [...prev, searchtext.trim()]);
      setSearchtext("");
    }
  };

  const [blogsdata, setBlogsdata] = useState([]);
  const loadblogs = async () => {
    setOnoffcompo(false);
    const RequestBody = {
      COURSE: selectedcourse_var?.SUBCOURSE || "",
      SEARCHITEMS: chips,
    };
    const response = await PostWitoutToken(DisplayblogsApi, RequestBody);
    if (response?.IsSuccess) {
      setBlogsdata(response?.BLOGS);
    } else {
      setOnoffcompo(true);
      setBlogsdata([])
    }
  };

  const handleCardClick = (blog) => {
    // Navigate to BlogDetails component with just the blog ID
    navigate(`/blog/${blog?.BLOGID}`);
  };

  return (
    <div style={{ width: "100%" }}>
      <TopDiv className="purpleBg whiteColor flexSpaceCenter">
        <Link
          className="pointer flexNullCenter"
          style={{ gap: "0px" }}
          to="home"
          smooth={true}
        >
          <img
            //   className={styles.navLogo}
            style={{
              width: "36px",
              height: "36px",
              alignSelf: "flex-start",
              // marginRight: "16px",
            }}
            alt="logo.png"
            src={LogoIcon}
          />
          <h1 style={{ marginLeft: "15px",color:"white" }} className="font20 extraBold">
            TagSkills
          </h1>
        </Link>
        <Button startIcon={<ArrowBack />} href="/" className="whiteColor">
          Back
        </Button>
      </TopDiv>

      <FormControl sx={{ m: 2, minWidth: 300 }}>
        <Autocomplete
          // Map `options` to the appropriate property for display
          options={dropdownData?.MAINCOURSE || []} // Ensure `MAINCOURSE` is not undefined
          getOptionLabel={(option) => option.SUBCOURSE || ""} // Use `SUBCOURSE` for display
          onChange={(event, value) => setSelectedcourse(value)} // `value` will be the selected object
          size="small"
          id="combo-box-demo"
          renderInput={(params) => (
            <TextField {...params} label="Category" variant="outlined" />
          )}
          PopperComponent={(props) => (
            <Popper
              {...props}
              style={{
                zIndex: 1300, // Ensure it's above other elements
              }}
              placement="bottom-start"
            />
          )}
        />
      </FormControl>

      <FormControl sx={{ marginLeft: 2, marginTop: 2, minWidth: 800 }}>
        <TextField
          value={searchtext}
          onChange={(e) => setSearchtext(e.target.value)}
          onKeyPress={(e) => {
            handleKeyPress(e);
          }}
          size="small"
          required
          //error={remark.length == 0}
          id="outlined-basic"
          label="Seach Topic Here...."
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={(e) => {
                    addChip(e);
                    loadblogs();
                  }}
                >
                  <SearchIcon onClick={loadblogs} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>

      <Box
        sx={{
          marginLeft: 2,
          marginTop: 1,
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        {chips.map((chip, index) => (
          <Chip
            key={index}
            label={chip}
            onDelete={() => handleDelete(chip)}
            color="primary"
          />
        ))}
      </Box>

      <Box sx={{marginLeft:4 }}>
        <Grid container spacing={2}>
          {blogsdata.map((blog) => (
            <Grid item xs={12} key={blog.id}>
              <BlogCard
                blog={blog}
                onClick={handleCardClick}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
{onoffcompo && (
      <Box sx={{ padding: 4 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "80px",
          border: "1px solid #ddd",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <p style={{ fontSize: "24px", fontWeight: "500", color: "#555" }}>
          No results found
        </p>
        <small style={{ fontSize: "16px", color: "#777" }}>
          Try a different keyword
        </small>
      </div>
      </Box>)}

      {/* <BlogDetails
            //   refreshcompo={refreshClick}
              selecteddata={selectedblog}
              setOnoffcompo={setOnoffcompo}
            ></BlogDetails> */}
    </div>
  );
}

const Wrapper = styled.section`
  padding-top: 30px;
  width: 100%;
  margin: 0;
  height: 100%;
  min-height: 30vh;
  max-height: 40vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    max-height: 100vh;
    min-height: 20vh;
    padding-top: 15px;
  }
`;
const TopDiv = styled.section`
  width: 100%;
  margin: 0;
  height: 100%;
  padding: 20px;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
`;
const SideDiv = styled.section`
  position: absolute;
  right: 50px;
  top: 30px;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    display: none;
  }
`;

const BroderContainer = styled.section`
  border: 1px solid lightgray;
  width: 100%;
  margin: 0;
  padding: 20px;
  height: 100%;
  max-width: 75%;
  min-height: 40vh;
  max-height: 60vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    max-height: 100vh;
    max-width: 100%;
  }
`;
const FooterDiv = styled.section`
  width: 100%;
  //   margin: 10px;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  //   max-width: 75%;
  //   min-height: 20vh;
  //   max-height: 20vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    max-height: 100vh;
    max-width: 100%;
  }
`;

const BroderLessContainer = styled.section`
  //   border: 1px solid lightgray;
  width: 100%;
  margin: 0;
  padding: 20px;
  height: 100%;
  max-width: 75%;
  min-height: 15vh;
  max-height: 20vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    max-height: 100vh;
    max-width: 100%;
  }
`;
const InnerWrapper = styled.section`
  //   padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 100%;
  max-width: 75%;
  margin: 0;
  height: 100%;
  //   min-height: 40vh;
  //   max-height: 60vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    max-height: 100vh;
    padding: 20px;
    max-width: 100%;
  }
`;
