import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { BlogDetailApi} from "src/api/ApiConnect";
import { PostWitoutToken } from "src/api/apiConfig";
import LogoIcon from "src/assets/icons/Logo.png";
import { ArrowBack } from "@mui/icons-material";
import styled from "styled-components";

const BlogDetails = () => {

  //const location = useLocation();
  const { id } = useParams();
  //const navigate = useNavigate();
  //const blog = location.state?.blog;

  const [blog, setBlogdata] = useState([]);

  useEffect(() => {
    loadblogdetails();
  }, []);

  const loadblogdetails = async () => {
 
    const RequestBody = {
      ID: id
    };
    const response = await PostWitoutToken(BlogDetailApi, RequestBody);
    if (response?.IsSuccess) {
      setBlogdata(response?.BLOGS[0]);
    } else {
      setBlogdata([])
    }
  };
  
  if (!blog) {
    return <Typography variant="h6">No Blog Found!</Typography>;
  }

  return (
    <div style={{ width: "100%" }}>
      <TopDiv className="purpleBg whiteColor flexSpaceCenter">
        <Link
          className="pointer flexNullCenter"
          style={{ gap: "0px" }}
          to="home"
          smooth={true}
        >
          <img
            //   className={styles.navLogo}
            style={{
              width: "36px",
              height: "36px",
              alignSelf: "flex-start",
              // marginRight: "16px",
            }}
            alt="logo.png"
            src={LogoIcon}
          />
          <h1 style={{ marginLeft: "15px",color:"white" }} className="font20 extraBold">
            TagSkills
          </h1>
        </Link>
      </TopDiv>
      <Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    marginTop: 1, // Remove any default margin
  }}
>
  <Box
    sx={{
      marginBottom: 10,
      justifyContent: "center",
      width: "80%",
      textAlign: "justify",
    }}
  >
    {/* Blog Title */}
    <Typography variant="h4" sx={{ marginTop: 2 }}>
      {blog?.BLOGTITLE}
    </Typography>
    {/* Blog Date */}
    <Typography
      variant="body2"
      color="textSecondary"
      sx={{
        display: "flex",
        alignItems: "center", // Aligns items vertically
        marginTop: 1,
        marginBottom: 1,
      }}
    >
      <CalendarTodayIcon fontSize="small" sx={{ marginRight: 0.5 }} />
      {blog?.BLOGDATETIME}, {blog?.COUNTRY}, By

      <a
        href={blog?.BYLINKEDINURL}
        target="_blank"
        rel="noopener noreferrer"
      >
        {`: ${blog?.WRITTENBY}`}
        <LinkedInIcon
          color="primary"
          sx={{ marginLeft: 0.5 }} // Add spacing between text and icon
        />
      </a>
    </Typography>
    {/* Blog Image */}
    <img
      src={blog?.IMG_URL}
      alt={blog?.BLOGTITLE}
      style={{ width: "100%", height: 400, borderRadius: "8px" }}
    />

    {/* Blog Content */}
    <div dangerouslySetInnerHTML={{ __html: blog?.BLOGCONTENT }} />
    {/* <Typography variant="body1" sx={{ marginTop: 2 }}>
    {blog?.BLOGCONTENT}
  </Typography> */}
    {/* Back Button */}
  </Box>
</Box>
    </div>
  );
};

export default BlogDetails;


const Wrapper = styled.section`
  padding-top: 30px;
  width: 100%;
  margin: 0;
  height: 100%;
  min-height: 30vh;
  max-height: 40vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    max-height: 100vh;
    min-height: 20vh;
    padding-top: 15px;
  }
`;
const TopDiv = styled.section`
  width: 100%;
  margin: 0;
  height: 100%;
  padding: 20px;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
`;
const SideDiv = styled.section`
  position: absolute;
  right: 50px;
  top: 30px;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    display: none;
  }
`;

const BroderContainer = styled.section`
  border: 1px solid lightgray;
  width: 100%;
  margin: 0;
  padding: 20px;
  height: 100%;
  max-width: 75%;
  min-height: 40vh;
  max-height: 60vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    max-height: 100vh;
    max-width: 100%;
  }
`;
const FooterDiv = styled.section`
  width: 100%;
  //   margin: 10px;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  //   max-width: 75%;
  //   min-height: 20vh;
  //   max-height: 20vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    max-height: 100vh;
    max-width: 100%;
  }
`;

const BroderLessContainer = styled.section`
  //   border: 1px solid lightgray;
  width: 100%;
  margin: 0;
  padding: 20px;
  height: 100%;
  max-width: 75%;
  min-height: 15vh;
  max-height: 20vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    max-height: 100vh;
    max-width: 100%;
  }
`;
const InnerWrapper = styled.section`
  //   padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 100%;
  max-width: 75%;
  margin: 0;
  height: 100%;
  //   min-height: 40vh;
  //   max-height: 60vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    max-height: 100vh;
    padding: 20px;
    max-width: 100%;
  }
`;
