import React, { useState, useEffect } from "react"
import { Link } from "react-scroll";
import styled from "styled-components";
import LogoIcon from "src/assets/icons/Logo.png";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import ActivityIndicator from "src/components/ActivityIndicator/ActivityIndicator"
import {
    Facebook,
    Instagram,
    LinkedIn,
    Mail,
    WhatsApp,
    YouTube,
  } from "@mui/icons-material";
  import gIcon from "src/assets/images/gIcon.png";
  import PeopleIcon from '@mui/icons-material/People';
  import { BookDemoApi ,MailSendingApi} from "src/api/ApiConnect"
import { PostWitoutToken } from "src/api/apiConfig";
import { useNavigate } from "react-router-dom";

export default function Contactus() {

  const navigate = useNavigate();

    const [loading, setLoading] = useState()
    //variable declaration 
    const queryParams = new URLSearchParams(window.location.search)
  const getcouponcode = queryParams.get("coupon")
  
  const [localfullname, setlocalfullname] = useState("");
  const [localmobile, setlocalmobile] = useState("");
  const [localemail, setlocalemail] = useState("");
  const [couponcode, setCouponcode] = useState(getcouponcode || "");

  //Validation declaration
const [localfullnameError, setlocalfullnameError] = useState(null);
const [localmobileError, setlocalmobileError] = useState(null);
const [localemailError, setlocalemailError] = useState(null);

const onSubmit= async (e) => {

    //Validation Check
    const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const pattern = new RegExp(/^[0-9\b]+$/)

    setlocalfullnameError(null);
    setlocalmobileError(null);
    setlocalemailError(null);


    if(localfullname.length==0)
    {
      setlocalfullnameError("Please enter your full name");
      return;
    }

    if(localmobile.length==0)
    {
      setlocalmobileError("Please enter your mobile number");
      return;
    }


    if(localemail.length==0)
    {
        setlocalemailError("Please enter your email");
      return;
    }

    if (!re.test(localemail)) {
        setlocalemailError("Enter valid Email Id")
        return
      }

    setLoading(true)

   
    //Api call
     const RequestBody = { 
        USER_FULLNAME: localfullname,
        USER_MOBILE: localmobile,
        USER_EMAIL: localemail,
        COURSE_CATEGORY: 'ERP',
        SOURCE:'CURIOSTUFF',
        COUPON: couponcode?.toUpperCase()
       }
      
       const data = await PostWitoutToken(BookDemoApi,RequestBody)
       mailsending();
       setLoading(false)
       if (data?.IsSuccess) {
         alert(data.Message)
       }else{
        alert(data.Message)
       }
  }

  const mailsending= async (e) => {

    const now = new Date();
    const formattedDate = now.toLocaleString();

    const RequestBody = { 
      NAME: localfullname,
      TIME: formattedDate
     }
    const data = await PostWitoutToken(MailSendingApi,RequestBody)
  }

  return (
    <div>
      <Wrapper className="purpleBg">
        <div>
          <Link
            className="pointer flexNullCenter"
            style={{ gap: "0px" }}
            to="home"
            smooth={true}
          >
            <img
              //   className={styles.navLogo}
              style={{
                width: "36px",
                height: "36px",
                alignSelf: "flex-start",
                // marginRight: "16px",
              }}
              alt="logo.png"
              src={LogoIcon}
            />
            <h1
              style={{ marginLeft: "15px" }}
              className="font20 extraBold whiteColor"
            >
              TagSkills
            </h1>
          </Link>
        </div>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className="grid1">
            <ContentWrapper>
              <Typography variant="h3" className="font30 whiteColor">
                Unlock Your Potential. Transform Today, Triumph Tomorrow.
              </Typography>
              <br />
              {/* <Typography variant="body2" className="font14 whiteColor">
              Empowering minds, transforming futures. Your journey to greatness begins here.
              </Typography> */}
              <br />
            </ContentWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="grid2"
            style={{ display: "flex", justifyContent: "center" }}
          ></Grid>
        </Grid>
      </Wrapper>
      <Wrapper style={{ paddingBottom: "30px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className="flexColumn flexCenter">
            <div>
              <div className="flexNullCenter">
                <LinkedIn
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/tag-skills",
                      "_blank"
                    )
                  }
                  style={{ cursor: "pointer", fill: "#68a3da" }}
                />
                <Typography variant="body2">18.77K+ Followers</Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <YouTube
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UCnrhyp2JvcNQb3kFeRziDLA",
                      "_blank"
                    )
                  }
                  style={{ cursor: "pointer", fill: "#ff2525" }}
                />
                <Typography variant="body2">8.33
8.33k+ Subscribers</Typography>
              </div>
              <br />{" "}
              <div className="flexNullCenter">
                <img
                  src={gIcon}
                  style={{ width: "20px", display: "flex", cursor: "pointer" }}
                  alt="icon"
                  onClick={() =>
                    window.open(
                      "https://www.google.com/search?q=tagskills&rlz=1C1CHBF_enIN994IN994&oq=tagskills&aqs=chrome..69i57j46i175i199i512j0i10l2j46i10i175i199j69i60l2j69i61.5342j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3bae3f50cd3efe11:0x45ac4731b6278edb,1,,,",
                      "_blank"
                    )
                  }
                />
                <Typography variant="body2">4.9 Rating on Google</Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <PeopleIcon style={{ cursor: "pointer", fill: "#ff2525" }} />
                <Typography variant="body2">1K+ Placements</Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">Expert Trainers </Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">
                  Business process knowledge
                </Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">Real time project</Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">Consultative approach</Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">Placement support</Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="flexCenter">
            <Box
              sx={{
                width: "400px",
                position: "absolute",
                backgroundColor: "white",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                flexGrow: 1,
                top: { md: "100px", lg: "150px" },
                position: { xs: "relative", md: "absolute", lg: "absolute" },
                // justifyContent: "end",
              }}
              style={{}}
            >
              <CardContent className="flexNullCenter flexColumn p30">
                <TextField
                  variant="outlined"
                  size="small"
                  className="pb10"
                  label="Full Name"
                  style={{ width: "100%" }}
                  value={localfullname}
                  onChange={(e) => {
                    setlocalfullname(e.target.value);
                  }}
                  helperText={localfullnameError}
                  autoComplete="off"
                  maxLength={25}
                />

                <TextField
                  variant="outlined"
                  size="small"
                  label="Email id"
                  className="pb10"
                  style={{ width: "100%" }}
                  value={localemail}
                  onChange={(e) => {
                    setlocalemail(e.target.value);
                  }}
                  helperText={localemailError}
                  autoComplete="off"
                  maxLength={25}
                />

                <TextField
                  variant="outlined"
                  size="small"
                  className="pb10"
                  label="Mobile number"
                  style={{ width: "100%" }}
                  id="mobile"
                  value={localmobile}
                  onChange={(e) => {
                    setlocalmobile(e.target.value);
                  }}
                  autoComplete="off"
                  helperText={localmobileError}
                />

                <TextField
                  variant="outlined"
                  size="small"
                  label="Enter Coupon Code (Optional)"
                  className="pb10"
                  style={{ width: "100%" }}
                  value={couponcode}
                  onChange={(e) => {
                    setCouponcode(e.target.value);
                  }}
                />
              </CardContent>
              <CardActions className="flexNullCenter flexColumn p30">
                <div className="flexNullCenter pb10">
                  <Typography variant="body2" className="font11">
                    By clicking Submit. you agree to achive communications from
                    Tagskills in accordance with our{" "}
                    <a href="#" className="lightPurpleColor" onClick={() => navigate("/Policy")}>
                      Privacy Policy
                    </a>
                  </Typography>
                </div>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  className="lightPurpleBg"
                  disabled={loading}
                  onClick={() => {
                    onSubmit();
                  }}
                  type="button"
                >
                  {!loading ? <p>Submit</p> : <ActivityIndicator />}
                </Button>
              </CardActions>
              <br />
            </Box>
          </Grid>
        </Grid>
      </Wrapper>
    </div>
  );
}
const Wrapper = styled.section`
  padding: 30px;
  width: 100%;
  margin: 0;
  height: 100%;
  //   min-height: 140px;
  //   max-height: 60vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 560px) {
    max-height: 100vh;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 60px;
  height: 100%;
`;
