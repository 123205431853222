import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Chip,FormControlLabel
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LocationOnIcon from "@mui/icons-material/LocationOn";


const BlogCard = ({ blog,onClick }) => {

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        boxShadow: 3,
        borderRadius: 2,
        marginBottom: 2,
        cursor: "pointer",
        transition: "transform 0.3s",
        "&:hover": { transform: "scale(1.02)" },
      }}
      onClick={() => onClick(blog)}
    >
      {/* Blog Image */}
      <CardMedia
        component="img"
        sx={{ width: 200 }}
        image={blog?.IMG_URL}
        alt={blog?.BLOGTITLE}
      />
      {/* Blog Content */}
      <Box sx={{ flex: 1, padding: 2 }}>
        {/* Blog Date */}
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
        >
          <CalendarTodayIcon fontSize="small" sx={{ marginRight: 0.5 }} />
          {blog?.BLOGDATETIME}
        </Typography>
        {/* Blog Title */}
        <Typography variant="h6" gutterBottom>
          {blog?.BLOGTITLE}
        </Typography>
        {/* Blog Category */}
        <Typography variant="subtitle2" color="primary" gutterBottom>
          {blog?.COURSE}
        </Typography>
        {/* Location and Author */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Chip
            icon={<LocationOnIcon fontSize="small" />}
            label={blog?.COUNTRY}
            size="small"
            variant="outlined"
          />
          <Typography variant="body2" color="textSecondary">
            By: {blog?.WRITTENBY}
          </Typography>
        
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
  <Typography
    variant="body1"
    sx={{
      marginTop: 2,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2, // Limits to 2 lines
      WebkitBoxOrient: "vertical", // Necessary for -webkit-line-clamp to work
    }}
  >
     <div
        dangerouslySetInnerHTML={{ __html: blog?.BLOGCONTENT }}
      />
    {/* {blog?.BLOGCONTENT} */}
  </Typography>
</Box>
      </Box>
    </Card>
  );
};
  export default BlogCard;