
export const ProfileUpdate = "/api/updateprofile";

export const ReviewDisplay = "/api/reviewall";

export const AllReviewDisplay = "/api/displayStories";

export const BlogDisplay = "/api/blogs";

export const AllCourseList = "/api/allcourse";

export const DashboardDisplay ="/api/dashboard";

export const UserDataApi ="/api/userdata";

export const NotificationDisplayApi ="/api/notification";

export const CertificateDisplayApi ="/api/getcertificate";

export const PdfDisplayApi ="/api/pdf";

export const VideoDisplayApi ="/api/displayfile";

export const YoutubeVideoApi ="/api/youtubevideos";

export const CourseoUpdateApi ="/api/courseupdate";

export const PaymentApi ="/api/payment";

export const PaymentStatusApi ="/api/payverify";

export const BookDemoApi ="/api/bookdemo";

export const SubCourseApi ="/api/getcourselist";

export const ChangePasswordApi ="/api/changepassword";

export const BatchDetailsApi ="/api/getbatchdetails?request=";

export const AdmissionApi ="/api/admission";

export const PaymentDetailsApi ="api/paymentdetails";

export const MailSendingApi ="/api/sendmail";

export const DisplayblogsApi ="/api/blogdisplay";

export const BlogDetailApi ="/api/blogdetails";



















